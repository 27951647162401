import { useClasses } from "./drawerContent.styles";
import { mergeClasses, useId } from "@fluentui/react-components";
import { QuillPersona } from "components/ui/QuillPersona";
import { QuillRoutes } from "config/routes";
import { NavLink, useParams } from "react-router-dom";
import { AuthContext } from "features/auth";
import { Dispatch, SetStateAction, useContext } from "react";
import { NavDivider, NavItem } from "@fluentui/react-nav-preview";
import { bundleIcon, Phone20Filled, Phone20Regular, People20Filled, People20Regular } from "@fluentui/react-icons";
import { DashboardNavValue } from "./types";
import { QuillPopover, QuillPopoverTrigger, QuillPopoverSurface } from "components/ui/QuillPopover";
import { UserProfileContent } from "features/sidePanel/features/collaborate/features/userProfile/UserProfileContent";

const Previews = bundleIcon(Phone20Filled, Phone20Regular);
const Members = bundleIcon(People20Filled, People20Regular);

type DrawerContentProps = {
  selectedNavValue: DashboardNavValue;
  setSelectedNavValue: Dispatch<SetStateAction<DashboardNavValue>>;
};

export function DrawerContent(props: DrawerContentProps) {
  const { selectedNavValue, setSelectedNavValue } = props;
  const classes = useClasses();
  const { signedInUser } = useContext(AuthContext);
  const { workspaceId } = useParams();
  const id = useId();

  const getAvatarProperties = (): Object => {
    if (!signedInUser) {
      return {};
    }
    return {
      image: signedInUser.photoURL ? { src: signedInUser!.photoURL } : {},
      color: "brand",
    };
  };

  const getDrawerLinkClassName = ({ isActive }: { isActive: boolean }) => {
    return isActive ? classes.drawerNavLinkActive : classes.drawerNavLink;
  };

  return (
    <>
      <NavLink
        to={QuillRoutes.getPreviews(workspaceId ?? "")}
        className={getDrawerLinkClassName}
        onClick={() => {
          if (selectedNavValue !== DashboardNavValue.previews) {
            setSelectedNavValue(DashboardNavValue.previews);
          }
        }}
      >
        <NavItem className={classes.drawerNavItem} as="button" icon={<Previews />} value={DashboardNavValue.previews}>
          Previews
        </NavItem>
      </NavLink>
      <NavLink
        to={QuillRoutes.getMembers(workspaceId ?? "")}
        className={getDrawerLinkClassName}
        onClick={() => {
          if (selectedNavValue !== DashboardNavValue.members) {
            setSelectedNavValue(DashboardNavValue.members);
          }
        }}
      >
        <NavItem className={classes.drawerNavItem} as="button" icon={<Members />} value={DashboardNavValue.members}>
          Members
        </NavItem>
      </NavLink>

      <div className={mergeClasses(classes.profileDrawerNavLink, classes.drawerNavLink)} data-testid="profile-popover">
        <NavDivider className={classes.navDivider} />
        <QuillPopover trapFocus positioning="above">
          <QuillPopoverTrigger disableButtonEnhancement>
            <NavItem className={classes.drawerNavItem} value="profile" as="button">
              <QuillPersona
                textPosition="after"
                textAlignment="center"
                name={signedInUser?.displayName ? signedInUser?.displayName : ""}
                primaryText={<b>{signedInUser?.displayName ? signedInUser?.displayName : ""}</b>}
                avatar={getAvatarProperties()}
              />
            </NavItem>
          </QuillPopoverTrigger>

          <QuillPopoverSurface
            tabIndex={-1}
            aria-labelledby={id}
            className={classes.profilePopover}
            data-testid="user-profile-content"
          >
            <UserProfileContent />
          </QuillPopoverSurface>
        </QuillPopover>
      </div>
    </>
  );
}
