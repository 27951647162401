import { makeStyles, tokens } from "@fluentui/react-components";

export const useClasses = makeStyles({
  formInputs: {
    marginTop: "30px",
    marginBottom: "30px",
    display: "flex",
    flexDirection: "column",
    rowGap: "10px",
  },

  formInputError: {
    ":after": {
      borderBottom: `2px solid ${tokens.colorPaletteRedBorder2}`,
    },
  },

  formInputErrorText: {
    color: tokens.colorPaletteRedForeground1,
    fontSize: "12px",
    marginTop: "-5px",
    alignItems: "center",
    display: "flex",
    columnGap: "5px",
  },

  content: {
    marginBottom: "30px",
  },
});
