import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
} from "@fluentui/react-components";

export const QuillDialog = Dialog;

export const QuillDialogSurface = DialogSurface;

export const QuillDialogBody = DialogBody;

export const QuillDialogTitle = DialogTitle;

export const QuillDialogContent = DialogContent;

export const QuillDialogActions = DialogActions;

export const QuillDialogTrigger = DialogTrigger;
