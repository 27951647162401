import { QuillEndpoints } from "./endpoints";
import { quillFetch } from "./util";

export class User {
  register(user: UserReq) {
    return quillFetch(QuillEndpoints.registerUser(), {
      method: "POST",
      body: JSON.stringify(user),
    });
  }
}

type UserReq = {
  email: string;
  uid: string;
  firstName: string;
  lastName: string;
};
