import { Toast, ToastBody, ToastTitle, ToastTrigger } from "@fluentui/react-components";

import { NotificationToastProps } from "../types";

import { useClasses } from "./notificationToast.styles";
import { QuillButton } from "components/ui/QuillButton";
import { DismissRegular } from "@fluentui/react-icons";

export function NotificationToast({ title, description }: NotificationToastProps) {
  const classes = useClasses();

  return (
    <Toast>
      <ToastTitle
        action={
          <ToastTrigger>
            <QuillButton className={classes.dismissButton} appearance="transparent">
              <DismissRegular />
            </QuillButton>
          </ToastTrigger>
        }
      >
        {title}
      </ToastTitle>
      <ToastBody>{description}</ToastBody>
    </Toast>
  );
}
