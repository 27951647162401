import { QuillAuthProviderId } from "config/types";
import { GitHost } from "../types";
import { auth } from "config/firebase";
import { QuillStorage } from "features/util/storage";

export const getProviderIdFromGitHost = (gitHost: GitHost): QuillAuthProviderId => {
  switch (gitHost) {
    case GitHost.github:
      return QuillAuthProviderId.github;
    case GitHost.gitlab:
      return QuillAuthProviderId.gitlab;
    default:
      return QuillAuthProviderId.unknown;
  }
};

export const isLoggedInToQuill = () => {
  return !!auth.currentUser;
};

export const isLoggedInToGit = () => {
  return QuillStorage.getAccessToken() != null;
};
