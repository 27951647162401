import { AuthContext } from "../../../../context/AuthContext";
import { useContext, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { GitHost } from "../../types";

import { useClasses } from "./loginPage.styles";
import { QuillButton } from "components/ui/QuillButton";
import { loadGitHostConfig } from "config/loadGitHostConfig";
import { QuillStorage } from "features/util/storage";

export function GitHostAuth() {
  const classes = useClasses();
  const { isGitLoggedIn, oauthClient, gitHost } = useContext(AuthContext);
  const code = QuillStorage.getCode();
  const shouldFetchToken = code !== null;

  const handleSignIn = () => {
    oauthClient?.authorize();
  };

  useEffect(() => {
    if (shouldFetchToken) {
      oauthClient?.getToken(code).then(() => {
        QuillStorage.deleteCode();
        window.location.replace("/");
      });
    }
  }, [code, oauthClient, isGitLoggedIn, gitHost, shouldFetchToken]);

  if (isGitLoggedIn) {
    return <Navigate to="/" />;
  }

  const signInWith = (gHost: GitHost) => {
    const gitHostConfig = loadGitHostConfig(gHost);
    return (
      <QuillButton
        disabled={shouldFetchToken}
        className={classes.loginButton}
        icon={gitHostConfig.buttonIcon}
        iconPosition="before"
        onClick={() => handleSignIn()}
      >
        {shouldFetchToken ? "Signing in ..." : `Sign in with ${gitHostConfig.name}`}
      </QuillButton>
    );
  };

  return (
    <div className={classes.login}>
      <div className={classes.loginButtons}>{signInWith(gitHost)}</div>
    </div>
  );
}
