import { QuillStorage } from "features/util/storage";
import { GitHost } from "../types";
import { oauth, OauthConfig } from "./oauth-config";
import backend from "client/backend";

export class OauthClient {
  private config: OauthConfig;

  private gitHost: string;

  constructor(gitHost: GitHost) {
    this.config = oauth[gitHost];
    this.gitHost = gitHost;
  }

  authorize = () => {
    const { authUrl, clientId } = this.config;
    const url = `${authUrl}&client_id=${clientId}&scopes=${this.config.scopes}`;

    window.location.replace(url);
  };

  getToken = async (code: string) => {
    const resp = await backend.auth.getAccessToken({
      code,
      git_host: this.gitHost,
      token_url: this.config.tokenUrl || "",
    });
    const body = await resp.json();
    this.storeToken(body);
  };

  refreshToken = async () => {
    const resp = await backend.auth.getAccessToken({
      refresh_token: QuillStorage.getRefreshToken() || "",
      git_host: this.gitHost,
      token_url: this.config.tokenUrl || "",
    });
    const body = await resp.json();
    this.storeToken(body);
  };

  private storeToken = (body: Record<string, any>) => {
    Object.keys(body).forEach((key) => localStorage.setItem(`${this.gitHost}_${key}`, body[key]));
  };
}
