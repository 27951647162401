import { User } from "firebase/auth";
import { OauthClient } from "./services";

export type ExtraUserInfo = {
  screenName?: string;
};

export type GitHubExtraUserInfo = {
  screenName: string;
};

export type AuthContextValues = {
  signedInUser: User | null;
  checkingAuthState?: boolean;
  isGitLoggedIn: boolean;
  isQuillLoggedIn: boolean;
  gitHost: GitHost;
  oauthClient: OauthClient | null;
};

export enum GitHost {
  github = "github",
  gitlab = "gitlab",
  bitbucket = "bitbucket",
  unknown = "unknown",
}
