import { makeStyles, shorthands, tokens } from "@fluentui/react-components";

export const useClasses = makeStyles({
  drawerContainer: {
    overflow: "hidden",
    display: "flex",
    height: "100vh",
    backgroundColor: tokens.colorNeutralBackground2,
    ...shorthands.transition("unset"),
    animation: "unset",
  },

  hamburgerDrawer: {
    position: "absolute",
    zIndex: "1000",
    top: "0",
    height: "30px",
    marginTop: "10px",
    marginLeft: "5px",
  },

  navDrawer: {
    minWidth: "260px",
    width: "260px",
  },

  drawerHeader: {
    marginLeft: "30px",
    width: "unset",
    ...shorthands.padding("5px", "0px"),
    paddingInlineStart: "0",
  },

  drawerBody: {
    ...shorthands.padding("0"),
    ":last-child": {
      paddingBottom: "0",
    },
  },

  dashBoardContent: {
    flex: "1",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    columnGap: tokens.spacingHorizontalXS,
    backgroundColor: tokens.colorNeutralBackground1,
    // Changing this will alter the behaviour of individual tab contents overflows
    overflowX: "hidden",
  },

  flexColumn: {
    flexDirection: "column",
  },
});
