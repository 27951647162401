import { User, UserCredential } from "firebase/auth";

import { ExtraUserInfo } from "../context/types";
import { CreateAxiosDefaults } from "axios";

export type IdleDetectorProps = {
  delay: number;
  onIdle: () => any;
  onActive?: () => any;
};

export enum LocalStorageKey {
  gitlabAccessToken = "gitlab_access_token",
  githubAccessToken = "github_access_token",
  gitlabRefreshToken = "gitlab_refresh_token",
  githubRefreshToken = "github_refresh_token",
  quill_initialLoginUrlParams = "quill_initialLoginUrlParams",
}

export enum SessionStorageKey {
  commentsScrollPosition = "commentsScrollPosition",
  gitHost = "git_host",
  prNumber = "pr_number",
  repo = "repo",
  owner = "owner",
  pkg = "pkg",
  url = "url",
  code = "code",
  idToken = "id_token",
}

export interface CustomUserCredential extends UserCredential {
  _tokenResponse: GitHubTokenResponse;
}

export interface CustomUser extends User {
  reloadUserInfo: ExtraUserInfo;
}

export type GitHubTokenResponse = {
  refreshToken: string;
};

export enum RequestStatus {
  unsent = "unsent",
  loading = "loading",
  successful = "successfull",
  failed = "failed",
}

export interface AxiosConfigOptions extends CreateAxiosDefaults {
  baseURL: string;
  params?: Record<string, any>;
  authorization?: string;
}
