import { GitHostConfig } from "./types";
import { Circle16Filled } from "@fluentui/react-icons";
import { emptyIcon } from "assets/icons";

export const generic: GitHostConfig = {
  name: "",
  pullRequest: "",
  buttonIcon: <Circle16Filled />,
  prStateIcons: {
    OPEN: emptyIcon,
    CLOSED: emptyIcon,
    MERGED: emptyIcon,
    UNKNOWN: emptyIcon,
  },
};
