import { useClasses } from "./dashboardLayout.styles";
import { useContext, useEffect, useState } from "react";
import { mergeClasses } from "@fluentui/react-components";
import { DrawerContent } from "./DrawerContent";
import { DrawerTop } from "./DrawerTop";
import { Hamburger, NavDrawer, NavDrawerBody, NavDrawerHeader } from "@fluentui/react-nav-preview";
import { DashboardNavValue } from "./types";
import { useLocation } from "react-router-dom";
import { QuillRoutes } from "config/routes";
import { WorkspacesContext } from "context/WorkspacesContext";

type DashboardLayoutProps = {
  children: React.ReactNode;
};

export function DashboardLayout(props: DashboardLayoutProps) {
  const { children } = props;
  const classes = useClasses();
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const { pathname } = useLocation();
  const { currentWorkspace } = useContext(WorkspacesContext);
  const workspaceId = (currentWorkspace && currentWorkspace.id) ?? "";

  const [selectedNavValue, setSelectedNavValue] = useState<DashboardNavValue>(DashboardNavValue.previews);

  useEffect(() => {
    const getCurrentNav = () => {
      if (pathname === QuillRoutes.getPreviews(workspaceId ?? "")) {
        return DashboardNavValue.previews;
      }

      if (pathname === QuillRoutes.getMembers(workspaceId ?? "")) {
        return DashboardNavValue.members;
      }
      return DashboardNavValue.previews;
    };

    const currentNav = getCurrentNav();
    if (currentNav !== selectedNavValue) {
      setSelectedNavValue(currentNav);
    }
  }, [pathname, workspaceId, selectedNavValue]);

  return (
    <>
      <div className={mergeClasses(classes.drawerContainer, classes.flexColumn)}>
        <div className={classes.drawerContainer} style={{ borderBottomWidth: 0 }}>
          <Hamburger
            data-testid="hamburger-menu"
            className={classes.hamburgerDrawer}
            onClick={() => setIsDrawerOpen(!isDrawerOpen)}
          />
          <NavDrawer
            open={isDrawerOpen}
            type="inline"
            size="medium"
            selectedValue={selectedNavValue}
            className={classes.navDrawer}
          >
            <NavDrawerHeader className={classes.drawerHeader}>
              <DrawerTop />
            </NavDrawerHeader>
            <NavDrawerBody className={classes.drawerBody}>
              <DrawerContent setSelectedNavValue={setSelectedNavValue} selectedNavValue={selectedNavValue} />
            </NavDrawerBody>
          </NavDrawer>
          <div className={classes.dashBoardContent}>{children}</div>
        </div>
      </div>
    </>
  );
}
