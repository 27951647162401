import { makeStyles } from "@fluentui/react-components";

export const useClasses = makeStyles({
  messageBarBody: {
    display: "flex",
  },

  messageBarTitle: {
    marginRight: "3px",
  },

  messageTooltip: {
    maxWidth: "100%",
    background: "none",
  },
});
