import { makeStyles, shorthands, tokens } from "@fluentui/react-components";

export const useClasses = makeStyles({
  drawerNavItem: {
    maxWidth: "100%",
    borderRadius: "0",
    ...shorthands.padding("15px", "20px"),
    ":hover": {
      backgroundColor: tokens.colorNeutralBackground3Selected,
    },
    ":hover:active": {
      backgroundColor: tokens.colorNeutralBackground3Pressed,
    },
  },

  profilePopover: {
    left: "10px !important",
  },

  drawerNavLink: {
    textDecoration: "none",
  },

  navDivider: {
    margin: "0",
  },

  drawerNavLinkActive: {
    backgroundColor: tokens.colorNeutralBackground4,
    textDecoration: "none",

    "& div": {
      backgroundColor: tokens.colorNeutralBackground4,
      ":hover": {
        backgroundColor: tokens.colorNeutralBackground4,
      },
    },
  },

  profileDrawerNavLink: {
    marginTop: "auto",
    ...shorthands.borderTop("1px", "solid", tokens.colorNeutralStroke3),
  },
});
