import { gitlabIcon } from "features/auth/icons";
import { GitHostConfig } from "./types";
import { emptyIcon, gitLabMRClosed, gitLabMRMerged, gitLabMROpen } from "assets/icons";

export const gitLab: GitHostConfig = {
  name: "GitLab",
  pullRequest: "merge request",
  buttonIcon: gitlabIcon,
  prStateIcons: {
    OPEN: gitLabMROpen,
    CLOSED: gitLabMRClosed,
    MERGED: gitLabMRMerged,
    UNKNOWN: emptyIcon,
  },
};
