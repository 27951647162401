import { GitHost } from "features/auth";
import { QuillStorage } from "features/util/storage";
import { useEffect, useState } from "react";

export const useGitHost = () => {
  const [gitHost, setGitHost] = useState<GitHost>(GitHost.unknown);

  useEffect(() => {
    const getGitHost = (gHost: string | null) => {
      switch (gHost) {
        case GitHost.github:
          return GitHost.github;
        case GitHost.gitlab:
          return GitHost.gitlab;
        default:
          return GitHost.unknown;
      }
    };
    const gHost = getGitHost(QuillStorage.getGitHost());
    setGitHost(gHost);
  }, [setGitHost]);

  return { gitHost };
};
