import { useMemo } from "react";
import { createRouter } from "./routes";
import { RouterProvider } from "react-router-dom";
import { AppProvider } from "./AppProvider";

function AppRouter() {
  const router = useMemo(() => createRouter(), []);

  return <RouterProvider router={router} />;
}

function App() {
  return (
    <AppProvider>
      <AppRouter />
    </AppProvider>
  );
}

export default App;
