import { makeStyles, shorthands, tokens } from "@fluentui/react-components";

export const useClasses = makeStyles({
  userProfile: {
    width: "300px",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  userDetails: {
    marginBottom: "15px",
  },

  userName: {
    width: "230px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  logoutButton: {
    marginTop: "auto",
    ...shorthands.padding("15px", 0),
  },

  detailsCard: {
    paddingLeft: "0px",
  },

  cardCaption: {
    color: tokens.colorNeutralForeground3,
  },
});
