import { makeStyles } from "@fluentui/react-components";

export const useClasses = makeStyles({
  pending: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
});
