import { QuillStorage } from "features/util/storage";

export const quillFetch = (input: string, options: RequestInit) => {
  const defaultHeaders = {
    Authorization: `${QuillStorage.getIdToken()}`,
    "content-type": "application/json",
    credentials: "include",
  };

  // eslint-disable-next-line no-param-reassign
  options.headers = { ...defaultHeaders, ...options.headers };

  return fetch(input, options);
};
