import { Button, Divider } from "@fluentui/react-components";
import { QuillPersona } from "components/ui/QuillPersona";
import { useContext } from "react";

import { useClasses } from "./userProfileContent.styles";
import { AuthContext } from "features/auth";
import { QuillCard, QuillCardHeader } from "components/ui/QuillCard";
import { QuillText } from "components/ui/QuillText";
import { QuillCaption1 } from "components/ui/QuillCaption";
import { signOutUser } from "features/auth/util";
import { Mail24Regular } from "@fluentui/react-icons";

export function UserProfileContent() {
  const classes = useClasses();
  const { signedInUser } = useContext(AuthContext);

  const getAvatarProperties = (): Object => {
    if (!signedInUser) {
      return {};
    }
    return {
      image: signedInUser.photoURL ? { src: signedInUser!.photoURL } : {},
      color: "brand",
    };
  };

  const handleSignOut = () => {
    signOutUser();
  };

  return (
    <div className={classes.userProfile}>
      <h3>My Profile</h3>
      {/* TODO: provide screen name as secondary text  */}
      <QuillPersona
        className={classes.userDetails}
        size="huge"
        textPosition="after"
        textAlignment="center"
        name={signedInUser?.displayName ? signedInUser?.displayName : ""}
        primaryText={{
          children: <b>{signedInUser?.displayName ? signedInUser?.displayName : ""}</b>,
          className: classes.userName,
        }}
        avatar={getAvatarProperties()}
      />
      <Divider />
      <h3>Contact Information</h3>
      <QuillCard appearance="subtle" className={classes.detailsCard}>
        <QuillCardHeader
          image={<Mail24Regular />}
          header={<QuillText weight="semibold">Email</QuillText>}
          description={<QuillCaption1 className={classes.cardCaption}>{signedInUser?.email}</QuillCaption1>}
        />
      </QuillCard>

      <div className={classes.logoutButton}>
        <Button onClick={handleSignOut}>Log out</Button>
      </div>
    </div>
  );
}
