import {
  MessageBar,
  MessageBarActions,
  MessageBarBody,
  MessageBarIntent,
  MessageBarTitle,
} from "@fluentui/react-components";
import { DismissRegular } from "@fluentui/react-icons";
import { QuillButton } from "../QuillButton";
import { Dispatch, ReactElement, SetStateAction } from "react";
import { useClasses } from "./quillMessageBar.styles";
import { QuillTooltip } from "components/ui/QuillTooltip";

export type QuillMessageBarProps = {
  children: ReactElement;
  title: string;
  description?: string;
  intent: MessageBarIntent;
  showMessage: boolean;
  setShowMessage: Dispatch<SetStateAction<boolean>>;
};

export function QuillMessageBar(props: QuillMessageBarProps) {
  const { children, title, description, intent, showMessage, setShowMessage } = props;
  const classes = useClasses();

  const dismissMessage = () => {
    setShowMessage(false);
  };

  const messageBar = (
    <MessageBar layout="singleline" key={1} shape="square" intent={intent}>
      <MessageBarBody className={classes.messageBarBody}>
        {title && <MessageBarTitle className={classes.messageBarTitle}>{title}</MessageBarTitle>}
        {description}
        <MessageBarActions
          containerAction={<QuillButton appearance="transparent" onClick={dismissMessage} icon={<DismissRegular />} />}
        />
      </MessageBarBody>
    </MessageBar>
  );
  return (
    <QuillTooltip
      visible={showMessage}
      content={{ children: messageBar, className: classes.messageTooltip }}
      positioning="above"
      relationship="description"
    >
      {children}
    </QuillTooltip>
  );
}
