import { emptyIcon } from "assets/icons";
import { GitHostConfig } from "./types";
import { GitMergeIcon, GitPullRequestClosedIcon, GitPullRequestIcon, MarkGithubIcon } from "@primer/octicons-react";

export const gitHub: GitHostConfig = {
  name: "Github",
  pullRequest: "pull request",
  buttonIcon: <MarkGithubIcon size={16} />,
  prStateIcons: {
    OPEN: <GitPullRequestIcon size={16} />,
    CLOSED: <GitPullRequestClosedIcon size={16} />,
    MERGED: <GitMergeIcon size={16} />,
    UNKNOWN: emptyIcon,
  },
};
