import { Menu, MenuButton, MenuItem, MenuList, MenuPopover, MenuTrigger } from "@fluentui/react-components";

export const QuillMenu = Menu;

export const QuillMenuPopover = MenuPopover;

export const QuillMenuList = MenuList;

export const QuillMenuItem = MenuItem;

export const QuillMenuButton = MenuButton;

export const QuillMenuTrigger = MenuTrigger;
