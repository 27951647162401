import { AuthContext } from "../../../../context/AuthContext";
import { useContext } from "react";

import { useClasses } from "./loginPage.styles";
import { QuillButton } from "components/ui/QuillButton";
import { googleIcon } from "features/auth/icons";
import { signIn } from "features/auth/util";
import { NotificationContext } from "context/NotificationContext";
import { Navigate, useLocation } from "react-router-dom";

export function LoginPage() {
  const classes = useClasses();
  const { checkingAuthState, isQuillLoggedIn } = useContext(AuthContext);
  const { notifyUser } = useContext(NotificationContext);
  const location = useLocation();

  const handleSignIn = async () => {
    try {
      await signIn();
      window.location.replace(`/git-host/login${location.search}`);
    } catch (error: unknown) {
      notifyUser({
        title: "You've not yet been invited to try out Quill.",
        type: "error",
        description: "Visit getquill.dev to join the waitlist",
      });
    }
  };

  if (isQuillLoggedIn && !checkingAuthState) {
    <Navigate to={`/git-host/login${location.search}`} />;
  }

  const signInButtons = () => (
    <div className={classes.container}>
      <QuillButton
        disabled={checkingAuthState}
        className={classes.loginButton}
        icon={googleIcon}
        iconPosition="before"
        onClick={() => handleSignIn()}
      >
        {checkingAuthState ? "Signing in..." : "Sign in with Google"}
      </QuillButton>
    </div>
  );

  return <div className={classes.login}>{signInButtons()}</div>;
}
