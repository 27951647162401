import { queryOptions, useQuery } from "@tanstack/react-query";
import { QueryConfig } from "utils/reactQuery";
import { QuillEndpoints } from "client/backend/endpoints";
import { Workspace } from "features/workspaces/types";

export const getWorkspaces = (): Promise<Workspace[]> => {
  // eslint-disable-next-line global-require -- importing axios fails in jest tests due to es6 module import rules
  const api = require("utils/axios");
  const axiosApi = api.getAxiosInstance(undefined, api.quillAuthRequestInterceptor);
  return axiosApi.get(QuillEndpoints.workspaces());
};

// Take advantage of TanStack's cacheing and query management
export const getWorkspacesQueryOptions = () =>
  queryOptions({
    // TODO: Find out how to make this unique. Maybe add userId
    queryKey: ["workspaces"],
    queryFn: () => getWorkspaces(),
  });

type UseWorkspacesOptions = {
  queryConfig?: QueryConfig<typeof getWorkspacesQueryOptions>;
};

export const useWorkspaces = ({ queryConfig }: UseWorkspacesOptions = {}) =>
  useQuery({
    ...getWorkspacesQueryOptions(),
    ...queryConfig,
  });
