import { LocalStorageKey, SessionStorageKey } from "utils/types";

export class QuillStorage {
  static getAccessToken() {
    switch (QuillStorage.getGitHost()) {
      case "gitlab":
        return localStorage.getItem(LocalStorageKey.gitlabAccessToken);
      default:
        return localStorage.getItem(LocalStorageKey.githubAccessToken);
    }
  }

  static getRefreshToken() {
    switch (QuillStorage.getGitHost()) {
      case "gitlab":
        return localStorage.getItem(LocalStorageKey.gitlabRefreshToken);
      default:
        return localStorage.getItem(LocalStorageKey.githubRefreshToken);
    }
  }

  static getGitHost() {
    return sessionStorage.getItem(SessionStorageKey.gitHost);
  }

  static getPrNumber() {
    return sessionStorage.getItem(SessionStorageKey.prNumber);
  }

  static getRepo() {
    return sessionStorage.getItem(SessionStorageKey.repo);
  }

  static getOwner() {
    return sessionStorage.getItem(SessionStorageKey.owner);
  }

  static getPkg() {
    return sessionStorage.getItem(SessionStorageKey.pkg);
  }

  static getUrl() {
    return sessionStorage.getItem(SessionStorageKey.url);
  }

  static getCode() {
    return sessionStorage.getItem(SessionStorageKey.code);
  }

  static deleteCode() {
    sessionStorage.removeItem(SessionStorageKey.code);
  }

  static setIdToken(idToken: string) {
    sessionStorage.setItem(SessionStorageKey.idToken, idToken);
  }

  static getIdToken() {
    return sessionStorage.getItem(SessionStorageKey.idToken) || "";
  }
}
