/* eslint-disable react/jsx-props-no-spreading */
import {
  Card,
  CardFooter,
  CardFooterProps,
  CardHeader,
  CardHeaderProps,
  CardPreview,
  CardPreviewProps,
  CardProps,
} from "@fluentui/react-components";

export function QuillCard(props: CardProps) {
  return <Card {...props} />;
}

export function QuillCardHeader(props: CardHeaderProps) {
  return <CardHeader {...props} />;
}

export function QuillCardPreview(props: CardPreviewProps) {
  return <CardPreview {...props} />;
}

export function QuillCardFooter(props: CardFooterProps) {
  return <CardFooter {...props} />;
}
