import { useClasses } from "./loadingPage.styles";

type LoadingPageProps = {
  message?: string;
};

export function LoadingPage({ message = "Loading..." }: LoadingPageProps) {
  const classes = useClasses();
  return <div className={classes.pending}>{message}</div>;
}
