import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QuillEndpoints } from "client/backend/endpoints";
import { Workspace } from "features/workspaces/types";
import { MutationConfig } from "utils/reactQuery";
import { getWorkspacesQueryOptions } from "./get-workspaces";

type CreateWorkspaceInputSchema = {
  name: string;
};

export const createWorkspace = async (data: CreateWorkspaceInputSchema): Promise<Workspace> => {
  // eslint-disable-next-line global-require -- importing axios fails in jest tests due to es6 module import rules
  const api = require("utils/axios");
  const axiosApi = api.getAxiosInstance(undefined, api.quillAuthRequestInterceptor);
  return axiosApi.post(QuillEndpoints.workspaces(), data);
};

type UseCreateWorkspaceOptions = {
  mutationConfig?: MutationConfig<typeof createWorkspace>;
};

export const useCreateWorkspace = ({ mutationConfig }: UseCreateWorkspaceOptions = {}) => {
  const queryClient = useQueryClient();

  const { onSuccess, ...restConfig } = mutationConfig || {};

  return useMutation({
    mutationFn: createWorkspace,
    onSuccess: (...args) => {
      queryClient.invalidateQueries({
        queryKey: getWorkspacesQueryOptions().queryKey,
      });
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};
