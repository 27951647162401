import { makeStyles, shorthands } from "@fluentui/react-components";

export const useClasses = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  login: {
    ...shorthands.flex(1),
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    ...shorthands.padding("15px"),
    textAlign: "center",
  },

  loginButtons: {
    display: "grid",
  },

  loginButton: {
    marginBottom: "5px",
    marginTop: "5px",
  },
});
