import { GitHost } from "../types";

export const oauth: Record<GitHost, OauthConfig> = {
  gitlab: {
    authUrl:
      `${process.env.REACT_APP_GITLAB_AUTH_URL}` +
      `?redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&response_type=code`,
    tokenUrl: process.env.REACT_APP_GITLAB_TOKEN_URL,
    clientId: process.env.REACT_APP_GITLAB_CLIENT_ID,
    scopes: "api",
  },
  github: {
    authUrl:
      `${process.env.REACT_APP_GITHUB_AUTH_URL}` +
      `?redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&response_type=code`,
    tokenUrl: process.env.REACT_APP_GITHUB_TOKEN_URL,
    clientId: process.env.REACT_APP_GITHUB_CLIENT_ID,
    scopes: "repo",
  },
  bitbucket: {
    authUrl: `${process.env.REACT_APP_BITBUCKET_AUTH_URL}?redirect_uri=http://localhost:3000/login&response_type=code`,
    tokenUrl: process.env.REACT_APP_BITBUCKET_TOKEN_URL,
    clientId: process.env.REACT_APP_BITBUCKET_CLIENT_ID,
    scopes: "",
  },
  unknown: {
    authUrl: `${process.env.REACT_APP_BITBUCKET_AUTH_URL}?redirect_uri=http://localhost:3000/login&response_type=code`,
    tokenUrl: process.env.REACT_APP_BITBUCKET_TOKEN_URL,
    clientId: process.env.REACT_APP_BITBUCKET_CLIENT_ID,
    scopes: "",
  },
};

export type OauthConfig = {
  authUrl: string | undefined;
  tokenUrl: string | undefined;
  clientId: string | undefined;
  scopes: string | undefined;
};
