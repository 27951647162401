import { QuillEndpoints } from "./endpoints";
import { quillFetch } from "./util";

export class Auth {
  getAccessToken(reqBody: tokenReqBody) {
    return quillFetch(QuillEndpoints.accessToken(), {
      method: "POST",
      body: JSON.stringify(reqBody),
    });
  }
}

type tokenReqBody = {
  code?: string;
  refresh_token?: string;
  git_host: string;
  token_url: string;
};
