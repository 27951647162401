import { FluentProvider, Toaster, useId } from "@fluentui/react-components";
import { QueryClientProvider } from "@tanstack/react-query";
import { ErrorFallback } from "components/ui/ErrorFallback/ErrorFallback";
import { LoadingPage } from "components/ui/LoadingPage/LoadingPage";
import { quilLightTheme } from "config/quillTheme";
import { NotificationProvider } from "context/NotificationContext";
import { AuthProvider } from "features/auth";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { HelmetProvider } from "react-helmet-async";
import { quillQueryClient } from "utils/reactQuery";

type AppProviderProps = {
  children: React.ReactNode;
};

export function AppProvider({ children }: AppProviderProps) {
  const mainToasterId: string = useId("toaster");
  return (
    <React.Suspense fallback={<LoadingPage />}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <HelmetProvider>
          <FluentProvider theme={quilLightTheme}>
            <AuthProvider>
              <QueryClientProvider client={quillQueryClient}>
                {/* Maximum of 1 toast notification at a time */}
                <Toaster toasterId={mainToasterId} limit={1} />
                <NotificationProvider toasterId={mainToasterId}>{children}</NotificationProvider>
              </QueryClientProvider>
            </AuthProvider>
          </FluentProvider>
        </HelmetProvider>
      </ErrorBoundary>
    </React.Suspense>
  );
}
