import { useRouteError } from "react-router-dom";

import error404 from "./error404.png";
import { useClasses } from "./errorPage.styles";

type ErrorPageProps = {
  title?: string;
  description?: string;
};

export function ErrorPage({
  title = "Oops!",
  description = "Sorry, an unexpected error has occurred.",
}: ErrorPageProps) {
  // const { title, description } = props;
  const error: any = useRouteError();
  const classes = useClasses();

  const errorStatus = error && error.status;
  const errorStatusText = error && error.statusText;
  const errorMessage = error && error.message;

  return (
    <div id="error-page" className={classes.error}>
      {errorStatus === 404 && <img className={classes.error404Image} src={error404} alt="Mobile Device" />}
      <h1>{title}</h1>
      <p>{description}</p>
      <div>{errorStatusText || errorMessage}</div>
    </div>
  );
}
