import { useToastController } from "@fluentui/react-components";
import { NotificationToast } from "components/ui/NotificationToast/NotificationToast";
import { createContext, useState } from "react";

import { NotificationContextValues, NotificationContextProps, NotificationState, NotifyUserOptions } from "./types";

const NotificationContext = createContext<NotificationContextValues>({
  /* eslint-disable @typescript-eslint/no-unused-vars */
  notifyUser: (options: NotifyUserOptions) => {},
  /* eslint-enable @typescript-eslint/no-unused-vars */
  triggerNotification: () => {},
  notificationDetails: { title: "", type: "info" },
});

function NotificationProvider({ children, toasterId }: NotificationContextProps) {
  const { dispatchToast } = useToastController(toasterId);
  const [notificationDetails, triggerNotification] = useState<NotificationState>({
    title: "",
    type: "info",
  });

  // A negative timeout means it will never be auto dismissed
  const notifyUser = ({
    title = "",
    type = "info",
    description = "",
    position = "top-end",
    timeoutInSeconds = -1,
  }: NotifyUserOptions) => {
    dispatchToast(<NotificationToast title={title} description={description} />, {
      position,
      timeout: timeoutInSeconds * 1000, // convert to milliseconds
      intent: type,
    });
  };

  return (
    <NotificationContext.Provider value={{ notifyUser, triggerNotification, notificationDetails }}>
      {children}
    </NotificationContext.Provider>
  );
}

export { NotificationContext, NotificationProvider };
