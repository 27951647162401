import { createContext, useEffect, useState } from "react";
import { User, onAuthStateChanged } from "firebase/auth";

import { AuthContextValues, GitHost } from "../features/auth/types";
import { useGitHost } from "hooks/useGitHost";
import { OauthClient } from "../features/auth/services";
import { QuillStorage } from "features/util/storage";
import { auth } from "config/firebase";
import { isLoggedInToGit } from "../features/auth/utils/common";

const defaultCtxValues = {
  signedInUser: null,
  isGitLoggedIn: false,
  isQuillLoggedIn: false,
  gitHost: GitHost.unknown,
  authService: null,
  oauthClient: new OauthClient(GitHost.github),
};

export const AuthContext = createContext<AuthContextValues>(defaultCtxValues);

export function AuthProvider({ children }: any) {
  const [isGitLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [isQuillLoggedIn, setIsQuillLoggedIn] = useState(false);
  const [checkingAuthState, setCheckingAuthState] = useState(false);
  const [signedInUser, setSignedInUser] = useState<User | null>(null);

  useEffect(() => {
    const params = new URLSearchParams(document.location.search);
    params.forEach((value, key) => sessionStorage.setItem(key, value));

    if (isLoggedInToGit()) {
      setIsLoggedIn(true);
    }

    setCheckingAuthState(true);
    onAuthStateChanged(auth, (user) => {
      user?.getIdToken().then((token) => QuillStorage.setIdToken(token));
      setCheckingAuthState(false);
      setIsQuillLoggedIn(!!user);
    });

    setSignedInUser(auth.currentUser);
  }, [isGitLoggedIn, isQuillLoggedIn]);

  return (
    <AuthContext.Provider
      value={{
        signedInUser,
        checkingAuthState,
        isGitLoggedIn,
        isQuillLoggedIn,
        gitHost: useGitHost().gitHost,
        oauthClient: new OauthClient(useGitHost().gitHost),
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
