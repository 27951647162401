import { ErrorPage } from "features/auth";
import { createBrowserRouter } from "react-router-dom";
import { RequireAuth } from "./auth/RequireAuth";
import { Root } from "./Root";
import { RoutePaths } from "config/routes";
import { DashboardRoute } from "./dashboard/DashboardRoute";

export const createRouter = () =>
  createBrowserRouter([
    {
      element: <Root />,
      children: [
        {
          element: <RequireAuth />,
          children: [
            {
              path: RoutePaths.ROOT,
              lazy: async () => {
                const { WorkspacesRoute } = await import("./dashboard/WorkspacesRoute");
                return { Component: WorkspacesRoute };
              },
            },
            {
              path: RoutePaths.INVITE,
              lazy: async () => {
                const { InviteRoute } = await import("./InviteRoute");
                return { Component: InviteRoute };
              },
            },
            {
              element: <DashboardRoute />,
              children: [
                {
                  path: RoutePaths.PREVIEWS,
                  lazy: async () => {
                    const { PreviewsRoute } = await import("./dashboard/PreviewsRoute");
                    return { Component: PreviewsRoute };
                  },
                },
                {
                  path: RoutePaths.PREVIEW,
                  lazy: async () => {
                    const { PreviewRoute } = await import("./dashboard/PreviewRoute");
                    return { Component: PreviewRoute };
                  },
                },
                {
                  path: RoutePaths.MEMBERS,
                  lazy: async () => {
                    const { MembersRoute } = await import("./dashboard/MembersRoute");
                    return { Component: MembersRoute };
                  },
                },
              ],
            },
          ],
        },
        {
          path: RoutePaths.LOGIN,
          lazy: async () => {
            const { LoginRoute } = await import("./auth/LoginRoute");
            return { Component: LoginRoute };
          },
        },
        {
          path: RoutePaths.GIT_HOST_LOGIN,
          lazy: async () => {
            const { GitHostAuthRoute } = await import("./auth/GitHostAuthRoute");
            return { Component: GitHostAuthRoute };
          },
        },
        {
          path: "login2",
          lazy: async () => {
            const { LoginRouteAux } = await import("./auth/LoginRouteAux");
            return { Component: LoginRouteAux };
          },
        },
      ],
      errorElement: <ErrorPage />,
    },
  ]);
