import { RouteNames } from "./types";

export const RoutePaths: RouteNames = {
  ROOT: "/",
  INVITE: "/invite",
  LOGIN: "/login",
  GIT_HOST_LOGIN: "/git-host/login",
  PREVIEWS: "/workspace/:workspaceId/previews",
  PREVIEW: "/workspace/:workspaceId/previews/:previewId",
  MEMBERS: "/workspace/:workspaceId/members",
};

export class QuillRoutes {
  static getRoot() {
    return RoutePaths.ROOT;
  }

  static getInvite() {
    return RoutePaths.INVITE;
  }

  static getLogin() {
    return RoutePaths.LOGIN;
  }

  static getGitHostLogin() {
    return RoutePaths.GIT_HOST_LOGIN;
  }

  static getPreviews(workspaceId: string) {
    return RoutePaths.PREVIEWS.replace(":workspaceId", workspaceId);
  }

  static getPreview(workspaceId: string, previewId: string) {
    return RoutePaths.PREVIEW.replace(":workspaceId", workspaceId).replace(":previewId", previewId);
  }

  static getMembers(workspaceId: string) {
    return RoutePaths.MEMBERS.replace(":workspaceId", workspaceId);
  }
}
