/* eslint-disable no-param-reassign */
import Axios, { AxiosInstance, CreateAxiosDefaults, InternalAxiosRequestConfig } from "axios";
import { QuillStorage } from "features/util/storage";

export function quillAuthRequestInterceptor(config: InternalAxiosRequestConfig) {
  if (config.headers) {
    config.headers.Authorization = `${QuillStorage.getIdToken()}`;
    config.headers["content-type"] = "application/json";
  }
  config.withCredentials = true;
  return config;
}

export const getAxiosInstance = (
  customConfig: CreateAxiosDefaults<any> | undefined,
  authRequestInterceptor: any,
): AxiosInstance => {
  const axios = Axios.create(customConfig);

  axios.interceptors.response.use(
    (response) => {
      return response.data;
    },
    (error) => {
      const message = error.response?.data?.message || error.message;
      console.error(error);
      console.log(message);

      return Promise.reject(error);
    },
  );
  axios.interceptors.request.use(authRequestInterceptor);
  return axios;
};
