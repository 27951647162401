import { InvitationStatus } from "features/members/types";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export class QuillEndpoints {
  static accessToken() {
    return `${BACKEND_URL}/auth/oauth/token`;
  }

  static registerUser() {
    return `${BACKEND_URL}/user/register`;
  }

  static workspaces() {
    return `${BACKEND_URL}/workspace`;
  }

  static updateInvite(workspaceId: string, inviteCode: string) {
    return `${BACKEND_URL}/workspace/${workspaceId}/invites?invite_code=${inviteCode}`;
  }

  static invite(workspaceId: string) {
    return `${BACKEND_URL}/workspace/${workspaceId}/invite`;
  }

  static invites(workspaceId: string, status: InvitationStatus) {
    return `${BACKEND_URL}/workspace/${workspaceId}/invites?status=${status}`;
  }

  static members(workspaceId: string) {
    return `${BACKEND_URL}/workspace/${workspaceId}/members`;
  }

  static previews(workspaceId: string) {
    return `${BACKEND_URL}/workspace/${workspaceId}/previews`;
  }
}
