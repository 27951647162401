import { GitHost } from "features/auth";
import { gitHub } from "./github";
import { gitLab } from "./gitlab";
import { GitHostConfig } from "./types";
import { generic } from "./generic";

export const loadGitHostConfig = (gHost: GitHost): GitHostConfig => {
  switch (gHost) {
    case GitHost.github:
      return gitHub;
    case GitHost.gitlab:
      return gitLab;
    default:
      return generic;
  }
};
